import { MAX_MOBILE_WIDTH } from '@/utils/constants';
import styled from 'styled-components';
import { CartButton } from '../../AtomicComponents';

export const CardContainer = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border: ${(props) => !props.imageUrl && `0.4px solid #C7C7C7;`};
  padding: ${(props) => !props.imageUrl && `20px;`};
  border-radius: ${(props) => !props.imageUrl && `4px;`};
  cursor: ${(props) =>
    props.isAvailable || props.isNotifyProductEnabled ? 'pointer' : 'default'};
  ${(props) =>
    !!props.isAvailable || props.isB2bStore ? null : 'filter: grayscale(1);'};

  @media screen and (max-width: 766px) {
    ${(props) => (!props.imageUrl ? ` grid-column: 1 / -1;` : ` max-width: 100%;`)}
  }
`;

export const CardBody = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: 100%;
`;

export const CardImage = styled.section`
  width: '100%';
  height: 'auto';
  position: relative;
  object-fit: contain;
  display: flex;

  & > img {
    width: 100%;
    object-position: center center;
  }
`;

export const CardMain = styled.section`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? '4px 6px' : '12px 0 14px')};
  position: relative;

  ${(props) =>
    props.imageUrl &&
    `
    @media screen and (max-width: 400px) {
      flex-direction: column;
    }
  `}
`;

export const CardData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CardInventory = styled.p`
  font-weight: 400;
  font-size: 12px;
  margin: 0 0 12px 0;
`;

export const CardTags = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: ${({ padding }) => padding};

  & > span:last-child {
    margin-bottom: 0;
  }
`;

export const CardTag = styled.span`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  padding: 2px 4px;
  margin-bottom: 4px;
  color: #ffffff;
  font-size: 11px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  white-space: nowrap;
  margin-right: 10px;
`;

export const CardTitle = styled.h3`
  font-weight: 500;
  margin: 0.5rem 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 14px;
  ${(props) => props.categoryCard && `text-align: center;`}
  font-size: 15px;
  word-break: break-word;
`;

export const CardPrice = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const AmountDiscounted = styled.span`
  display: inline-block;
  color: ${(props) => props.fontColor};
  letter-spacing: 1.5px;
  font-size: 16px;
`;

export const AmountActual = styled.span`
  display: inline-block;
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.3);
  letter-spacing: 1.3px;
  margin-left: 10px;
  font-size: 13px;
`;

export const CardDiscount = styled.span`
  display: inline-block;
  position: absolute;
  background-color: #3ea200;
  color: #ffffff;
  font-size: 11px;
  text-transform: uppercase;
  z-index: 2;
  padding: 2px 3px;
  ${(props) => (props.imageUrl ? `bottom: 0px;` : `top: -25px;`)}
`;

export const ButtonContainer = styled.section`
  display: flex;
  flex: 0;
  flex-direction: column;

  @media screen and (min-width: 401px) {
    margin-left: 10px;
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 400px) {
    margin-top: 10px;
  }
`;

export const Button = styled(CartButton)`
  cursor: pointer;
  @media screen and (min-width: 767px) {
    font-size: 22px;
  }

  @media screen and (max-width: 766px) {
    font-size: 18px;
  }
`;

export const CardDescription = styled.p`
  margin: 0;
  color: #9c9c9c;
  font-weight: 400;
  letter-spacing: 0.26px;
  line-height: 1.5;

  ${(props) =>
    props.isAvailable &&
    `
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;    
    `};

  @media screen and (min-width: 767px) {
    font-size: 12px;
  }

  @media screen and (max-width: 766px) {
    font-size: 11px;
  }
`;

export const B2BPriceText = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => props.fontColor};
  letter-spacing: 1.5px;
  font-size: 14px;
  font-weight: 700;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    font-size: 13px;
  }
`;
