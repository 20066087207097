import { IMAGE_CONFIG_CONSTANT } from './constants';
import {
  componentTypeEnums,
  getImageUrlFromCompressedImages,
  getMaxImgSizeForComponent,
} from './compressedImagesHelpers';

/*
 returns either image_url or thumbnail_url,
 depending on the configuration of theme config 'product_image_quality_flag'
 */
export function returnSpecificImageUrl({ theme, item }) {
  if (!theme || !theme?.product_image_quality_flag)
    return getImageUrlFromCompressedImages(item?.compressed_images, item?.image_url, {
      maxImgSize: getMaxImgSizeForComponent(componentTypeEnums.CARD),
    });

  return theme?.product_image_quality_flag === IMAGE_CONFIG_CONSTANT.THUMBNAIL_IMAGE
    ? item?.thumbnail_url || item?.image_url
    : getImageUrlFromCompressedImages(item?.compressed_images, item?.image_url, {
        maxImgSize: getMaxImgSizeForComponent(componentTypeEnums.CARD),
      });
}
