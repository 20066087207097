/**
 * common function to return config data for card configuration changes
 * @param {*} {activeCategoryConfig, productConfig}
 * @return {*} object - config
 */
export const returnCommonConfigData = ({
  activeCategoryConfig = {},
  productConfig = {},
}) => {
  if (activeCategoryConfig && Object.keys(activeCategoryConfig).length > 0) {
    return activeCategoryConfig;
  } else if (productConfig && Object.keys(productConfig).length > 0) {
    return productConfig;
  } else {
    return {};
  }
};
